import React from "react"
import Heading2 from "../common/Heading2"
import { training } from "../data/Data"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Button, Modal, Stack, Typography } from "@mui/material";



const style = {
  position: 'absolute',
  top: '52%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '60vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};




const TrainingCard = () => {

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({
    cover:'',
    title:'',
    bullets:[]
  });
  const handleOpen = (items) => {
    setOpen(true)
    setData(items)
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className='content grid2 mtop' >
        {training.map((items, index) => (
          <div className='box' key={index}>
            <div>
                <h2 style={{float:'left', marginLeft:'3%'}}>{items.title}</h2>
            </div>
            <div>
              <button className='btn2' style={{float:'right', margin:'2% 0%'}} onClick={(e) => {handleOpen(items)}}>Learn More</button> 
            </div>
          </div>
        ))}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} style={{ overflowY:'scroll'}}>
          <div className='box'>
            <center>
                <h2>{data.title}</h2>
            </center>
            <div style={{marginTop:'10%', marginLeft:'10%'}}>
              {
                  data.bullets.map((item, index)=>{
                    return <div style={{height:'100%',overflowY:'scroll'}}>
                              <Typography variant="body1" style={{textAlign:'left', margin:'2%'}}>{item}</Typography>
                            </div>
                  })
              }
            </div>
          </div>
        </Box>
      </Modal>
      </div>
    </>
  )
}

export default TrainingCard

