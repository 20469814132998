import { Grid } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom';

function KiramDetail() {
    const {state} = useLocation();
    const {data} = state;
    return (
      <div style={{margin:'5%'}}>
         <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} >
                  <center>
                   <img src={data.cover} style={{width:'60%'}}/>
                  </center>
               </Grid>
               <Grid item xs={12} sm={12} md={6} lg={6}>
                 <center>
                  <h4 style={{textAlign:'left'}}>{data.name}</h4>
                  <h4 style={{textAlign:'left'}}>{data.honor}</h4>
                  <h4 style={{textAlign:'left'}}>{data.position}</h4>
                 </center>
              </Grid>
             <Grid item xs={12} sm={12} md={12} lg={12}>
                 <center>
                      <h1>Professional Background</h1>
                 </center>
             </Grid>
             <Grid item xs={12} sm={12} md={12} lg={12}>
              <p style={{margin:'2%', textAlign:'justify'}}>{data.professional_background}</p>
             </Grid>
            </Grid>
          </div>
          )
}

export default KiramDetail