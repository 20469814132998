import { ArrowRight } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import React from "react"
import Heading2 from "../common/Heading2"
import { services } from "../data/Data"


const FeaturedCard = () => {
  return (
    <>
      <div className='content grid2 mtop' >
        {services.map((items, index) => (
          <div className='box' key={index} style={{height:'100%'}}>
            
            <Heading2 title={items.title} />
            <h4 style={{margin:'5%', textAlign:'left'}}>{items.name}</h4>
            {
              items.bullets.map((bullet)=>{
               return <Stack direction="row" alignItems="center"  gap={1} style={{marginLeft:'5%'}}>
                        <ArrowRight style={{color:'#1a2138 '}}/> 
                        <Typography variant="body1">{bullet}</Typography>
                      </Stack>
              })
            }
          </div>
        ))}
      </div>
    </>
  )
}

export default FeaturedCard


// <img src={items.img} alt='image not found' style={{width:'40%', height:'30%'}}/>