import React from "react"

const HeadingH1 = ({ title, subtitle }) => {
  return (
    <>
      <div className='heading1'>
       <center>
        <h1 style={{margin:'8%', color:'white'}}  className='header_first_h1'>{title}</h1>
      </center>
        <p style={{margin:'3%', color:'white', display:'block'}} className='header_first_p'>{subtitle}</p>
      </div>
    </>
  )
}

export default HeadingH1
