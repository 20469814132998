import { Markup } from 'interweave';
import React from 'react'
import { useLocation } from 'react-router-dom'

function MoreNews() {
  const {state} = useLocation();

  return (
    <center>
        <div style={{margin:'5% auto'}}>
            <h1>{state.title}</h1>
            <img src={state.img} alt='image not found!' style={{margin:'5% 10%', width:'60%'}}/>
            <div style={{margin:'2% 20%', textAlign:'justify'}}>
                <Markup content={state.content} style={{textAlign:'left'}}/>
            </div>
        </div>
    </center>
  )
}

export default MoreNews