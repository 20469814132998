import React from "react"
import Header from "../common/header/Header"
import { BrowserRouter as Router, Switch, Route, Routes, Navigate } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import About from "../about/About"
import Blog from "../blog/Blog"
import Services from "../services/Services"
import Contact from "../contact/Contact"
import ViewNews from "../news/ViewNews"
import MoreNews from "../news/MoreNews"
import TeamDetail from "../home/team/TeamDetail"
import KiramDetail from "../home/team/KiramDetail"
import TamratDetail from "../home/team/TamratDetail"
import JaletaDetail from "../home/team/JaletaTesgera"

const Pages = () => {

  
  return (
    <>
        <Header />
        <Routes>
          <Route exact path='/' element={  <Home/>}  />
          <Route exact path='/about'  element={ <About/> }  />
          <Route exact path='/services'  element={ <Services/>}/>
          <Route exact path='/blog'  element={  <Blog/>} />
          <Route exact path='/contact'  element={  <Contact/>} />
          <Route exact path='/viewNews'  element={ <ViewNews/>  } />
          <Route exact path='/detail'  element={  <MoreNews/>} />
          <Route exact path='/team_detail'  element={ <TeamDetail/>  } />
          <Route exact path='/kiram_detail'  element={ <KiramDetail/> } />
          <Route exact path='/tamrat_detail'  element={  <TamratDetail/> } />
          <Route exact path='/jaleta_tesgera'  element={   <JaletaDetail/> } />
        </Routes>
        <Footer />
    </>
  )
}

export default Pages
