import React from "react"

const Heading2 = ({ title, subtitle }) => {
  return (
    <>
      <div className='heading1'>
        <h3 style={{margin:'3%'}}>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </>
  )
}

export default Heading2
