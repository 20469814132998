import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useLocation } from 'react-router-dom';

function JaletaDetail() {

  const {state} = useLocation();
  const {data} = state;
  return (
    <div style={{margin:'5%'}}>
      <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4} >
                <center>
                 <img src={data.cover} style={{width:'60%'}}/>
                </center>
             </Grid>
             <Grid item xs={12} sm={12} md={6} lg={4}>
               <center>
                <h4 style={{textAlign:'left'}}>{data.name}</h4>
                <h4 style={{textAlign:'left'}}>{data.position}</h4>
               </center>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
               <center>
                 <h4 style={{textAlign:'left'}}>Email: {data.email}</h4>
                 <h4 style={{textAlign:'left'}}>Phone: {data.phone}</h4>
                 <h4 style={{textAlign:'left'}}>LinkedIn: {data.linkedin}</h4>
                 <h4 style={{textAlign:'left'}}>Github: {data.github}</h4>
                 <h4 style={{textAlign:'left'}}>Website: {data.website}</h4>
              </center>
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} >
                <h3>Education</h3>
                <h4 style={{ margin:'2% 4%'}}>{data.education.school}</h4>
                <p style={{textAlign:'justify', marginLeft:'4%'}}>{data.education.description}</p>
                <p style={{textAlign:'justify', marginLeft:'4%'}}>{data.education.gpa}</p>
            </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4 style={{ margin:'2% 0%'}}>Student Organizations</h4>
                <p style={{textAlign:'justify', marginLeft:'4%'}}>{data.student_organizations}</p>
            </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} >
                <h4 style={{ margin:'2% 0%'}}>Relevant Course Work</h4>
                <p style={{textAlign:'justify', marginLeft:'4%'}}>{data.relevent_course_work}</p>
            </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} >
                <h3>Technical Skills</h3>
                <h4 style={{margin:'2% 0%'}}>Programming Languages</h4>
                <p style={{textAlign:'justify', marginLeft:'4%'}}>{data.technical_skills.programming_languages}</p>
                <h4 style={{margin:'2% 0%'}}>Developer Tools</h4>
                <p style={{textAlign:'justify', marginLeft:'4%'}}>{data.technical_skills.developer_tools}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <h2>Work Experience</h2>
            </Grid>
           {
            data.work_experience.map((item)=>{
              return <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h4>{item.place}</h4>
                    <p style={{textAlign:'justify', margin:'2% 4%'}}>{item.position}</p>
                    {
                        item.description.map((detail)=>{
                          return <p style={{textAlign:'justify', margin:'1% 4%'}}>{detail}</p>
                        })
                    }
               </Grid>
            })
           }
           <Grid item xs={12} sm={12} md={12} lg={12} >
                <h2>Projects</h2>
            </Grid>
           {
            data.projects.map((item)=>{
              return <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h4 style={{margin:'2% 4%'}}>{item.title}</h4>
                    {
                        item.description.map((detail)=>{
                          return <p style={{margin:'2% 4%', textAlign:'justify'}}>{detail}</p>
                        })
                    }
               </Grid>
            })
           }
            
        </Grid>
    </div>
  )
}

export default JaletaDetail