import React from 'react'
import Heading from '../common/Heading'
import TrainingCard from './TrainingCard'

function Training() {
  return (
    <>
      <section className='featured background' style={{backgroundColor:'white'}}>
        <div className='container'>
          <Heading title='Training Programs' subtitle='' />
          <TrainingCard />
        </div>
      </section>
    </>
  )
}

export default Training