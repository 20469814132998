import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from '@mui/material';
import { deleteDoc} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Alert, Grid, Snackbar, Stack, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { db, storage } from '../../firebase/firebase';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
 
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: 'Image',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'snippet',
    numeric: false,
    disablePadding: false,
    label: 'Snippet',
  },
  {
    id: 'update',
    numeric: false,
    disablePadding: false,
    label: 'Update',
  },
  {
    id: 'delete',
    numeric: false,
    disablePadding: false,
    label: 'Delete',
  },
  {
    id: 'detail',
    numeric: false,
    disablePadding: false,
    label: 'Detail',
  },
  
 
];

function EnhancedTableHead(props) {
  const { order, orderBy,  onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export default function NewsTable(props) {

  var rows= props.rows;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const style = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #224876',
    borderShadow: '2%',
    borderRadius: '25px',
    padding:'2%'
  };
  const style_1 = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #224876',
    borderShadow: '2%',
    borderRadius: '25px',
  };
  

  const [News,setNews]=useState([]) 

    const [open, setOpen] = useState(false);
  const handleOpen = (row) =>{
    setNews(row);
    setOpen(true);
  }

  const handleClose = () =>{
    setOpen(false);
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  

   const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const navigate = useNavigate();

  const handleDetail = (row) =>{
    navigate('/detail', { state: { ...row } });
  }

  const handleDeleted  = async() =>{
    const deleteRef = doc(db, "news", id);
        await deleteDoc(deleteRef);
    handleClose_1();
  }

  const [id, setId] = useState('');
  const [open_1, setOpen_1] = useState(false);

  const handleOpen_1 = (row) => {
    console.log('open')
    setId(row.id);
    setOpen_1(true)
  };

  const handleClose_1 = () => setOpen_1(false);


  
 const [data, setData] = useState({});
 const [per, setPerc] = useState(null);
 const [file, setFile] = useState("");


  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setNewsData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleUpdate = async() =>{
    const updateRef = doc(db, 'news', newsData.id);
    await updateDoc(updateRef, {
      ...newsData,
      lastUpdated : serverTimestamp()
  });
  handleClose();
 }


  const [newsData,setNewsData] = useState({
    title: '' ,
    snippet: '' ,
    content: '',
    img:''
   })


   const modules = {
    toolbar: {
      container: [  
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
        ['bold', 'italic', 'underline','link'],  
        [
            { 'list': 'ordered' },
            { 'list': 'bullet' },
            { 'indent': "-1" },
            { 'indent': "+1" }
        ],  
        [{ 'align': [] }],  
        ['clean'],  
        [{ 'color': [] }]  
      ],
  
   }
    }

    const formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image', 'video'
    ]

    const handleChange = (value) =>{
      setNewsData({...newsData, content: value});
    }

   

 const StyledButton = styled(Button)(({theme})=>({
   backgroundColor:'#03a1fc',
    size: 'small'
  }))

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const handleSuccess = () => {
    setOpenSuccess(true);
  };

  const handleError = () => {
    setOpenError(true);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  return (
    <Box sx={{ width: '70vw', marginBottom:'10%', height:'100vh' }}>
      <Paper sx={{ width: '70vw', mb: 2 }}>
        
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
             
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                     
                    >
                      <TableCell align="center">
                        <Avatar src={row.img} alt='not provided'/>
                      </TableCell>
                      <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">{row.snippet}</TableCell>
                      <TableCell align="center">
                        <FontAwesomeIcon icon={faEdit} color='#224876' size='lg' onClick={(e)=>{
                          setNewsData(row)
                          setOpen(true)
                        }}/> 
                      </TableCell>
                      <TableCell align="center">
                        <FontAwesomeIcon icon={faTrash} color='#224876' size='lg' onClick={(e) => handleOpen_1(row)}/> 
                      </TableCell>
                      <TableCell align="center">
                        <button style={{backgroundColor:'#224876', color:'white', borderRadius:"25px"}} onClick={(e)=>handleDetail(row)}>Detail</button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ ...style_1, width: '50%' }}>
        <center>
        <div style={{backgroundColor:'#224876', color:' #E4EDED', borderTopLeftRadius: '25px', borderTopRightRadius: '25px'}}>
          <center>
            <Typography variant='h6' sx={{p : 3}}>Update News</Typography>
          </center>
        </div> 
        
        <div>
         
          <Grid container spacing={2} style={{ overflowY:'scroll', height:'50vh', marginTop:'4%', padding:'4%'}}>
          
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{float:'left'}}>
        <div className="bottom">
          <div className="left">
            <img
              src={
                newsData.img
              }
              alt=""
            />
          </div>
          </div>
          <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{float:'left'}}>
        <TextField
          label="Title"
          id="outlined-size-small"
          size="small"
          value={newsData.title}
          onChange={(e)=> setNewsData({...newsData, title: e.target.value} )}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{float:'left'}}>
        <TextField
          label="Snippet"
          id="outlined-size-small"
          size="small"
          value={newsData.snippet}
          onChange={(e)=> setNewsData({...newsData, snippet: e.target.value} )}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{float:'left', width:'100%'}}>
           <center>
           <ReactQuill 
                theme="snow" 
                modules={modules}
                value={newsData.content}   
                formats={formats}
                onChange={handleChange}
            />
           </center>
        </Grid> 
        
        <StyledButton variant='contained' onClick={handleUpdate} sx={{m:'5% auto'}}>Save</StyledButton>
      <center>
     
          
          </center> 
      </Grid>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
      <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
         Data Added Successfully!
      </Alert>
    </Snackbar>
    <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
      <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }}>
         Data Added Successfully!
      </Alert>
    </Snackbar>
        </div>
        </center>
        </Box>
      </Modal>
    </div>
    <Modal
        open={open_1}
        onClose={handleClose_1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style}}>
         <Grid container>
            <Grid xs={12} md={12} sm={12} lg={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to delete this!
              </Typography>
            </Grid>
            <Grid xs={6} md={6} sm={6} lg={6}>
              <button onClick={handleDeleted} style={{float:'right', width : '10vw',margin:'5%', border:'none', borderRadius:'25px', backgroundColor:'#1a2138', color:' #E4EDED'}}>Yes</button>
            </Grid>
            <Grid xs={6} md={6} sm={6} lg={6}>
              <button onClick={handleClose_1} style={{float:'left', width:'10vw',margin:'5%', border:'none', borderRadius:'25px', backgroundColor:'#1a2138', color:' #E4EDED'}}>No</button>
            </Grid>
         </Grid>
        </Box>
      </Modal>

    </Box>
  );
}
