import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useLocation } from 'react-router-dom';

function TamratDetail() {

  const {state} = useLocation();
  const {data} = state;
  return (
    <div style={{margin:'5%'}}>
      <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4} >
                <center>
                 <img src={data.cover} style={{width:'60%'}}/>
                </center>
             </Grid>
             <Grid item xs={12} sm={12} md={6} lg={4}>
               <center>
                <h4 style={{textAlign:'left'}}>{data.name}</h4>
                <h4 style={{textAlign:'left'}}>{data.position}</h4>
               </center>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
               <center>
                 <h4 style={{textAlign:'left'}}>Email: {data.email}</h4>
                 <h4 style={{textAlign:'left'}}>Phone: {data.phone}</h4>
                
              </center>
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12}>
               <center>
                    <h1>Professional Background</h1>
               </center>
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12}>
            <p style={{margin:'2%', textAlign:'justify'}}>{data.professional_background}</p>
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'5% 0%'}}>
               <center>
                    <h1>Education</h1>
               </center>
               </Grid>
               <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card style={{padding:'2%'}}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>PhD</h2>
                        </center>
                        <CardContent>
                        <p style={{textAlign:'justify'}}>{data.education_phd}</p>
                        </CardContent>
                </Card>
               </Grid>
               <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card style={{padding:'2%'}}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>Masters</h2>
                        </center>
                        <CardContent>
                        <p style={{textAlign:'justify'}}>{data.education_masters}</p>
                        </CardContent>
                </Card>
               </Grid>
               <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card style={{padding:'2%'}}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>Degree</h2>
                        </center>
                        <CardContent>
                        <p style={{textAlign:'justify'}}>{data.education_degree}</p>
                        </CardContent>
                </Card>
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>CERTIFICATES/PROFESSIONAL TRAININGS
                            </h2>
                        </center>
                        {
                          data.certificates && <p style={{margin:'3% 5%', textAlign:'justify' }}>{data.certificates}</p>
                        }
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>GRANTS, FELLOWSHIPS & SCHOLARSHIP AWARDS
                            </h2>
                        </center>
                        {
                          data.grants && data.grants.map((research)=>{
                             return <p style={{margin:'3% 5%', textAlign:'justify' }}>{research}</p>
                          })
                        }
                          
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'2% 0%'}}>
                 <center>
                    <h1>PUBLICATIONS</h1>
                    <h3>LIST OF INTERNATIONALLY REFEREED PUBLICATIONS </h3>
                 </center>
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'1% 0%'}}>
                    <center>
                        <h2 style={{fontWeight:'bold', margin:'1%'}}>JOURNAL PAPERS </h2>
                    </center>
                    {
                        data.journal_papers && data.journal_papers.map((journal_paper)=>{
                          return  <p style={{margin:'3% 5%', textAlign:'justify' }}>{journal_paper}
                            </p>
                        })
                    }
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>REFEREED PAPERS IN INTERNATIONAL CONFERENCE 
                            PROCEEDINGS</h2>
                        </center>
                          {
                            data.refered_papers.map((refered_papers)=>{
                               return <p style={{margin:'3% 5%', textAlign:'justify' }}>{refered_papers}</p>
                            })
                          }
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>LIST OF NON-REFEREED PUBLICATIONS </h2>
                        </center>
                        {
                          data.non_refered_publication.map((non_refered_publication)=>{
                             return <p style={{margin:'3% 5%', textAlign:'justify' }}>{non_refered_publication}</p>
                          })
                        }
               </Grid>
        </Grid>
    </div>
  )
}

export default TamratDetail