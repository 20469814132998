import React from "react"

const Heading3 = ({ title, subtitle, color }) => {
  return (
    <>
      <div className='heading' >
        <center>
            <h1 style={{color: color}}>{title}</h1>
        </center>
        <p style={{color: color, textAlign:'justify'}}>{subtitle}</p>
      </div>
    </>
  )
}

export default Heading3
