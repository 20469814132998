import React,{ Component } from 'react';
import { Carousel } from 'react-carousel-minimal';
import img_1 from '../resources/img_1.jpg';
import img_2 from '../resources/img_2.jpg';
import img_3 from '../resources/img_3.jpg';
import img_4 from '../resources/img_4.jpg';
import img_5 from '../resources/img_5.jpg';
import img_6 from '../resources/img_6.jpg';

const Back = (props) => {
  const data = [
    {
      image: img_1,
      caption: props.title
    },
    {
      image: img_2,
      caption: props.title
    },
    {
      image: img_3,
      caption: props.title
    },
    {
      image: img_4,
      caption: props.title
    },
    {
      image: img_5,
      caption: props.title
    },
    {
      image: img_6,
      caption: props.title
    },
  ];


  const captionStyle = {
    fontSize: '4em',
    fontWeight: 'bold',
    float:'left'
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <>
        <div>
          <Carousel
            data={data}
            time={2000}
            width="100vw"
            height="40vh"
            captionStyle={captionStyle}
            captionPosition="center"
            automatic={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            style={{
              textAlign: "left",
              width: "100vw",
              maxHeight: "500px",
              
            }}
          />
        </div>
    </>
  )
}

export default Back
