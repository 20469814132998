import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD_daNNbpcvD2sn_LaaGB4OBCtQrIBeEz4",
  authDomain: "ultimate-5adb2.firebaseapp.com",
  projectId: "ultimate-5adb2",
  storageBucket: "ultimate-5adb2.appspot.com",
  messagingSenderId: "182685728515",
  appId: "1:182685728515:web:eb351cebd69fc0855b3336"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();