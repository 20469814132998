import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {db} from '../../../firebase/firebase';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const RecentCard = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    const q = query(collection(db, "news"), orderBy('timeStamp','desc'));
    const unsub = onSnapshot(q, (snapShot) => {
      let list = [];
      snapShot.docs.forEach(doc=>{
        list.push({id: doc.id, ...doc.data()});
      })
      setNews(list);
      console.log(news)
  },(error)=>{
     console.log(error);
  });
  return () =>{
    unsub();
  }
  }, []);

  const navigate = useNavigate();

  const handleDetail = (data) =>{
    navigate('/detail', { state: { ...data } });
  }

 
  
  
  return (
    <>
      <a href="#0">
        <button  style={{ position:'absolute', right: 60, marginTop:'220px', zIndex:'999', float:'right',borderRadius: '25px'}}>
          <ArrowUpwardIcon color="white" fontSize="0.5"/>
        </button>
      </a>
      <a href={'#' + (news.length - 1) }>
        <button  style={{ position:'absolute', right: 60, marginTop:'300px', zIndex:'999', float:'right',borderRadius: '25px'}}>
          <ArrowDownwardIcon color="white" fontSize="0.5"/> 
        </button>
      </a>
      <div className='content grid3 mtop ' style={{height:'80vh', overflowY:'scroll'}}>
        
        {news.map((val, index) => {
          return (
            <div className='box shadow' key={index} id={index.toString()} style={{boxShadow:'1px 2px 3px 3px #c7c9c7', margin:'1% 1%'}}>
              <div className='img' >
                <img src={val.img} alt='' style={{height:'30vh'}}/>
              </div>
              <div className='text' style={{height:'25vh', overflowY:'scroll', marginBottom:'2%'}}>
               
                <h4>{val.title}</h4>
                <p style={{textAlign:'justify'}}>
                  {val.snippet}
                </p>
              </div>
              <div className='button flex' style={{height:'10vh'}}>
                <div>
                  <button className='btn2' style={{float:'right'}} onClick={(e)=> {handleDetail(val)}}>Read More</button> 
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default RecentCard
