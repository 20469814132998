import { Facebook, LinkedIn, Telegram, YouTube } from "@mui/icons-material"
import React from "react"
import { footer } from "../../data/Data"
import "./footer.css"
import { Grid } from "@mui/material"
const Footer = () => {
  return (
    <>
      <Grid container>
      <Grid item xs={12} lg={12} sm={12} md={12}>
          <footer className='footer'>
            <div className='container'>
                  <div className='row'>
                        <div className='footer-col'>
                          <ul  style={{color:'#bbbbbb', textAlign:'justify'}}>
                                <li>
                                  <img src='../images/logowhite.png' alt='Image not found' style={{width:'80%', height:'40%'}}/>
                                </li>
                          </ul>
                        </div>
                        <div className='footer-col' style={{textAlign:'center',}}>
                          <h4>Quick Links</h4>
                          <ul>
                                <li><a href="/">home</a></li>
                                <li><a href="/about">about </a></li>
                                <li><a href="/services">services</a></li>
                                <li><a href="/blog">News</a></li> 
                                <li><a href="/contact">contact</a></li>
                          </ul>
                        </div>
                        <div className='footer-col'>
                          <h4>contact us</h4>
                          <ul>
                              <li>Email:</li>
                              <li style={{marginLeft:'5%'}}>info@ucsethiopia.com</li>
                              <li style={{marginLeft:'5%'}}>ftesgerajetu@yahoo.com</li>
                              <li>Telephone:</li> 
                              <li style={{marginLeft:'5%'}}>+251911256485</li>
                              <li style={{marginLeft:'5%'}}>+251911445721</li>
                              <li>P.o.Box: 12682, Addis Ababa, Ethiopia</li>
                              <li>Address: Yeka Sub-City, Woreda 12, House No. 1027</li>
                          </ul>
                        </div>
                      
                        <div className='footer-col'>
                          <h4>follow us</h4>
                          <div className='social-links'>
                              <a href="#">
                              <YouTube style={{fontSize:'30px'}}/>
                              </a>
                              <a href="#">
                                <LinkedIn style={{fontSize:'30px'}}/>
                              </a>
                              <a href="#">
                                <Telegram style={{fontSize:'30px'}}/>
                              </a>
                              <a href="#">
                                <Facebook style={{fontSize:'30px'}}/>
                              </a>
                          </div>
                        </div>
                  </div>
            </div>
        </footer> 
      </Grid>
</Grid>
    </>
  )
}

export default Footer
