import React, { useState, useContext } from "react"
import "./header.css"
import { nav } from "../../data/Data"
import { Link, useNavigate } from "react-router-dom"
import { Avatar, Box, Menu } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const [navList, setNavList] = useState(false)

 


  const navigate =  useNavigate();

  return (
    <>
      <header style={{height:'100%'}}>
        <div className='container flex'>
         <Link to='/'>
          <div className='logo'>
            <img src='./images/logo-light.png' alt='' />
          </div>
         </Link>
          <div className='nav'>
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  <Link to={list.path}>{list.text}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className='toggle'>
            <button style={{backgroundColor:'white', color:'black'}} onClick={() => setNavList(!navList)}>{navList ? <CloseIcon /> : <MenuIcon style={{fontSize:'150%'}}/>}</button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
