import React from "react"

const Heading1 = ({ title, subtitle }) => {
  return (
    <>
      <div className='heading1'>
        <h1 style={{margin:'5%'}}>{title}</h1>
        <p  style={{textAlign:'justify', margin:'5%'}}>{subtitle}</p>
      </div>
    </>
  )
}

export default Heading1
