import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import React from 'react';
import { team } from '../../data/Data';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useLocation } from 'react-router-dom';

function TeamDetail() {

  const {state} = useLocation();
  const {data} = state;
  return (
    <div style={{margin:'5%'}}>
      <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4} >
                <center>
                 <img src={data.cover} style={{width:'60%'}}/>
                </center>
             </Grid>
             <Grid item xs={12} sm={12} md={6} lg={4}>
               <center>
                <h4 style={{textAlign:'left'}}>{data.name}</h4>
                <h4 style={{textAlign:'left'}}>{data.honor}</h4>
                <h4 style={{textAlign:'left'}}>{data.position}</h4>
               </center>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
               <center>
                 <h4 style={{textAlign:'left'}}>Email: {data.email}</h4>
                 <h4 style={{textAlign:'left'}}>Phone: {data.phone}</h4>
                
              </center>
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12}>
               <center>
                    <h1>Professional Background</h1>
               </center>
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12}>
            <p style={{margin:'2%', textAlign:'justify'}}>{data.professional_background}</p>
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'5% 0%'}}>
               <center>
                    <h1>Education</h1>
               </center>
               </Grid>
               <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card style={{padding:'2%'}}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>PhD</h2>
                        </center>
                        <CardContent>
                        <p style={{textAlign:'justify'}}>{data.education_phd}</p>
                        </CardContent>
                </Card>
               </Grid>
               <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card style={{padding:'2%'}}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>Masters</h2>
                        </center>
                        <CardContent>
                        <p style={{textAlign:'justify'}}>{data.education_masters}</p>
                        </CardContent>
                </Card>
               </Grid>
               <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card style={{padding:'2%'}}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>Degree</h2>
                        </center>
                        <CardContent>
                        <p style={{textAlign:'justify'}}>{data.education_degree}</p>
                        </CardContent>
                </Card>
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'5% 0%'}}>
                 <center>
                    <h1>Experience</h1>
                 </center>
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card style={{padding:'2%'}}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>Selected Training Experiences
                            </h2>
                        </center>
                        <Stack direction={'column'} gap={2}>
                        {
                          data.training_experience && data.training_experience.map((training)=>{
                             return <Stack direction="row" alignItems="center"  gap={1}>
                                  <Typography variant="body1">{training}</Typography>
                              </Stack>
                          })
                        }
                          
                      </Stack>
                </Card>
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card style={{padding:'2%'}}>
                        <center>
                            <h2 style={{fontWeight:'bold', margin:'5%'}}>Selected Research Work, Conference Papers, and Publications
                            </h2>
                        </center>
                        <Stack direction={'column'} gap={2}>
                        {
                          data.research_work && data.research_work.map((research)=>{
                             return <Stack direction="row" alignItems="top"  gap={1}>
                                  <Typography variant="body1" style={{textAlign:'justify'}}>{research}</Typography>
                              </Stack>
                          })
                        }
                          
                      </Stack>
                </Card>
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'5% 0%'}}>
                    <center>
                        <h2 style={{fontWeight:'bold', margin:'5%'}}>Selected Relevant Experiences</h2>
                    </center>
                    {
                        data.experience && data.experience.map((experience)=>{
                          return  <p style={{margin:'3% 5%', textAlign:'left'}}>
                                <bold style={{fontWeight:'bold'}}>{experience.title}</bold>{" :   "}{experience.duration}
                            </p>
                        })
                    }
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'1% 0%'}}>
                 <center>
                    <h1>Training Acquired</h1>
                 </center>
               </Grid>

               <Grid item xs={12} sm={12} md={12} lg={12}>
                            <h2 style={{fontWeight:'bold', margin:'3%'}}>International</h2>
                          {
                            data.relevant_training_international.map((international)=>{
                               return <p style={{margin:'2% 3%', textAlign:'justify' }}>{international}</p>
                            })
                          }
               </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12}>
                            <h2 style={{fontWeight:'bold', margin:'3%'}}>Local</h2>
                        {
                          data.relevant_training_local.map((local)=>{
                             return <p style={{margin:'2% 3%', textAlign:'justify' }}>{local}</p>
                          })
                        }
                          
               </Grid>
        </Grid>
    </div>
  )
}

export default TeamDetail