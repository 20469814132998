import Promotion from '../resources/promotion.png';
import Advisory from '../resources/counselor.png';
import Training from '../resources/analysis.png';
import Research from '../resources/research.png';
import Awash3 from '../resources/awash3.jpg';
import Awash4 from '../resources/awash4.jpg';
import Ultimate_1 from '../resources/ultimate_1.jpg';
import Ultimate_2 from '../resources/ultimate_2.jpg';

export const homeData = [
  {
    id: 1,
    cover: Awash3,
  },
  {
    id: 2,
    cover: Awash4,
  },
  {
    id: 3,
    cover: Ultimate_1,
    
  },
  {
    id: 4,
    cover: Ultimate_2,
  },
]

export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "news",
    path: "/blog",
  },
  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Enthusiasm in exceeding clients’ expectations",
    total: "122 Property",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Provision of cost effective and quality service",
    total: "155 Property",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Endurance in terms of commitment to end results",
    total: "300 Property",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Synergy with clients and partners",
    total: "80 Property",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Dedication to integrity and respect for professionalism",
    total: "80 Property",
  },
]

export const training = [
  
  
  {
    cover: "../images/training/executive.png",
    title:'Executive Development',
    bullets: [
      "Driving Strategic Innovation", 
      "Organizational Change and Transformation Management",
      "Corporate Governance and Leadership",
      "Digital Transformation Management",
      "Leading with Impact: Transformational Leadership",
      "Leading Customer Centric Strategies",
      "Strategic Partnership Management", 
      "Risk and Compliance Management",
      "Capital Markets and Opportunities",
    ],
  },
  {
    cover: "../images/training/management.png",
    title:'Management Development',
    bullets: [
      "Capital/Financial Markets",
      "Risk Based Internal Audit",
      "Humanistic Leadership",
      "Strategic Thinking and Strategy Execution",
      "Leading in the Digital Age: Leadership Skills for the Digital Age",
      "Business Analytics for Leaders: Data Driven Decision Making",
      "Becoming a Transfomational Leader/Transformational Leadership",
      "Leading with Emotional Intelligence",
      "Problem Solving and Decision Making",
      "Digital Business Leadership",
      "Change and Knowledge Management",
      "Enterprise Risk Management",
      "Talent Management & Retention Strategies",
      "Leading High Performance Teams",
      "Customer Experience Management",
      "Organizational Leadership: Driving Culture and Performance",
      "Attitudinal Transformation and Ethical Leadership",
      "Organizational Skills and Stress Management",
      "Team Building and Coaching",
    ],
  },
  {
    cover: "../images/training/professionals.png",
    title:'Professional Development',
    bullets: [
      "Design Thinking and Business Innovation", 
      "Marketing  Skills (Product & Service Marketing)",
      "Project Management Professional - PMP",
      "Customer R/P Management (CEM)",
      "Emotional Intelligence: Inner Leadership",
      "Digital Marketing Skills",
      "Leadership Skills Development",
      "Attitudinal Transformation and Workplace Ethics",
      "Team Skills: Communicating Effectively in Groups",
      "Customer Service Excellence/Customer Centric Culture",

    ],
  },
  
  {
    cover: "../images/training/it.png",
    title:'Information Technology  (IT)',
    bullets: [
      "Artificial intelligence (AI) for Business",
      "ITIL (Foundation and Intermediate)",
      "Cloud Security – Certified Cloud Security Professional (CCSP)",
      "Certified Information Systems Security Professional (CISSP)",
      "Governance of Enterprise IT (CGEIT)",
      "Cyber Security for the Financial Industry: Managing Risk in the Information Age",
      "Certified Information System Auditor (CISA)",
      "COBIT 2019 Framework: Introduction and Methodology (IT Governance)",
      "Certified Risk and Informaiton System Control (CRISC)",
      "Red Hat System Administration (I, II, and III)",
      "CCNA and CCNP",
      "Checkpoint Certified Administrator" ,
      "Big Data and Edge Computing",
      "Business Agility, Digital Transformation and IT Strategy",
      "Design Thinking and Business Innovation ",
      "IT Service Management",
      "Risk and Business Continuity Planning",
      "Others: Server, storage, Vmware, Microsoft, Oracle",
    ],
  },
  {
    cover: "../images/training/it.png",
    title:'Finance',
    bullets: [
      "Financial Modeling",
      "Capital/Financial Markets  and Forex Trading", 
      "Finance for non Finance managers ",
      "Accounting & Financial Analysis",
      "Enterprise Risk and Compliance Management",
      "Operational Risk in Time of Crisis",
      "Project planning and controlling",
      "Risk Based Auditing",
      "Internal Audits (risk based) ",
    ],
  },
  {
    cover: "../images/training/it.png",
    title:'Human Resources (HR)',
    bullets: [
      "Communication, emotional intelligence & leadership skills",
      "Artificial intelligence and machine learning - data, the new currency",
      "Navigating uncertainty and equipping workers with skills and tools",
      "Beyond balance sheet - Hr evaluation and accounting",

    ],
  },
  {
    cover: "../images/training/it.png",
    title:'Marketing & Pub. Relations ',
    bullets: [
      "Business communication, ethics and analysis",
      "Business communication with data",
      "Critical Thinking and Innovative Skills for Competitive Advantage",
      "Public Leadership and management",
      "Achieving Excellence in Customer Service ",
      "Marketing and Business Growth Strategies",
      "Digital Marketing Strategy",
      "Public Relation Management",

    ],
  },
  {
    cover: "../images/training/it.png",
    title:'Other Programs ',
    bullets: [
      "Interest Free Banking (IFB) Training",
      "Takaful Training",
      "Executive Personal assistant "

    ],
  },
]


export const services = [
  {
    title: 'Training',
    name: "UCS  provides training services to help organizations develop the cutting-edge knowledge and skills needed to remain abreast of the latest learning curve. It offers:",
    bullets: [
      "Executive development program", 
      "Management development program",
      "Professional development program",
      "IT training program",
      "Other programs"
    ],
    img: Training
  },
  {
    title: 'Advisory',
    name: "UCS also offers consultancy and advisory services including: ",
    bullets: [
      "Strategic plan dev’t", 
      "Organizational change mgt",
      "Transformation mgt",
      "Organizational dev’t",
      "Project portfolio mgt ",
      "Other related services.", 
    ],
    img: Advisory
  },

  {
    title: 'Research and Publication',
    name: "UCS conducts studies in order to support organizations open up new field of possibilities (opportunities) and  foster sustainable growth both in the short and long term. The research and publication services include:",
    bullets: [
      "Organizational studies", 
      "Feasibility studies",
      "Sector studies",
      "Customer surveys",
      "Opinion surveys",
      "Baseline surveys, and",
       "Study for turnkey projects"
    ],
    img: Research
  },
  {
    title: 'Communication and Promotion',
    name: "UCS supports organizations in the  documentation of best practices as well as in the preparation of communication and promotion resource materials. Such services include the following:",
    bullets: [
      "Educational materials", 
      "Annual newsletters",
      "Brochures",
      "Flyers",
      "Company profile, and", 
      "Other related services.", 
    ],
    img: Promotion
  },
  
]

export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
]

export const team = [
  {
    cover: "../images/team/team-1.jpg",
    email:'ftesgerajetu@yahoo.com',
    phone:'251911256485/0911445721',
    name: "Fanta Tesgera (PhD)",
    position:"Managing Director, Ultimate Consultancy Services (UCS)",
    honor:"Assistant Professor of Management",
    professional_background:'Dr Fanta Tesgera  is a certified consultant, the  founder and Managing Director of Ultimate Consultancy Services (UCS) and Assistant Professor of Management.  He has Doctoral Degree in Social and Economic Sciences (PhD) from JKU, Austria, MBA and BA from AAU. Overall, he has more than 25 years of work experience in consulting, training, managing and higher education teaching. He has worked with the high profile companies in Ethiopia at various  capacities.  He has also published a book and peer reviewed articles in international journals.',
    education_degree:'BA Degree in Management and Public Administration,  Addis Ababa University, Addis Ababa, Ethiopia',
    education_masters:'Masters in Business Administration (MBA), Addis Ababa University, Addis Ababa, Ethiopia',
    education_phd:'Doctoral Degree in Social and Economic Sciences, Johannes Kepler University (JKU), Austria',
    training_experience:[
      "Project Management",
      "Corporate Governance and Leadership",
      "Organizational Change and Transformation Mgt.",
      "Digital Leadership and Transformation",
      "Project and Program Management",
      "Visioning and Strategic Thinking",
      "Corporate Strategic Planning",
      "Transformational Leadership",
      "Leadership Skills Development",
      "Corporate Rebranding",
      "Project Planning and Proposal Development",
      "Result Based Monitoring & Evaluation",
      "Project Monitoring and Evaluation with a focus on Earned Value Management",
      "Leadership and Motivation",
      "Supervisory Management",
      "Workplace Relationship and Conflict Management",
      "Employee Empowerment and Engagement",
      "Customer Centric Strategies and Product Innovation",
      "Customer Experience Management",
      "Time and Stress Management",
      "Supervisory Communications ",
      "Operations Management ",
      "Cross-Functional Communication in Organizations",
    ],
    research_work:[
      "Fanta T. and Yohannes Neda (2020): The Rebirth of Private Banks and Insurance Companies in Ethiopia: The Case of Awash Bank and Awash Insurance Company, 25th Anniversary Book of Awash Bank and Awash Insurance.",

      "Jetu, F.T., and Riedl, R. (2013). Cultural Values Influencing Project Team Success: An Empirical Investigation in Ethiopia. International Journal of Managing Projects in Business, Volume 6, Issue 3, pp 425-456.",

      "Jetu, F.T., Riedl, R. (June 2012). Determinants of Information Systems and Information Technology (IST) Project Team Success: A Literature Review and a Conceptual Model, Communications of the Association for Information Systems, Volume 30, Article 27, pp. 455-482.",

      "Global Leadership: Challenges and Emerging Perspectives, Journal of International Leadership Institute (JILI), 2012",
      
      "Jetu, F.T., Riedl, R., and Roithmayr, F. (2011). Cultural Patterns Influencing Project Team Behaviors in Sub-Saharan Africa: A Case Study in Ethiopia, Project Management Journal, Vol. 42, No. 5, 57-77.",
      
      "Jetu, F.T. (2011). The Cultural and Behavioral Dimension of Project Management: Factors Influencing Project Team Success in Ethiopia, TraunerVerlag, Linz (Book).",
      
      "Jetu, F.T. (2011). A Framework for the Design and Plan of Project Teams in Organization-Wide Transformational Projects. National Conference on Management for Effectiveness, Efficiency and Ethics, June 30 and July 1, 2011, Ethiopian Management Institute, Addis Ababa, Ethiopia.",
      
      "Jetu, F.T., Riedl, R., and Roithmayr, F. (2010): The Application and Use of Success Factor Analysis in Project Team Settings. The Proceedings of the 24th IPMA World Congress: Challenges and Opportunities, 1-3 November 2010, Istanbul, Turkey.",
      
    ],

    relevant_training_local:[
      "Corporate Governance Workshop organized by KPMG, Addis Ababa Ethiopia.",
      "National Conference on Management for Effectiveness, Efficiency and Ethics, Ethiopian Management Institute, Addis Ababa, Ethiopia.",
      "Advanced Research Methods Workshop, organized by the Institute of Population Studies, AAU and University of Bristol, UK.",
      "Research Methodology and Proposal Writing, organized by the Faculty of Business and Economics, AAU.",
      "Leadership and Change Management, the College of Telecommunications and Information Technology, Ethiopian Telecommunications Corporation.",
      "Balanced Scorecard Workshop, organized by International Telecommunication Union in partnership with African Advanced Level Telecommunications Institute held at Addis Ababa, Ethiopia",
      "Participated in other training programs like International Banking Operations, Training of Trainers (ToT) programs, and so forth.",
    ],
    relevant_training_international:[
      "Capital Markets and Opportnities, Kenya",
      "Board Masterclass, UAE",
      "Corporate Governance Workshop organized by KPMG, Addis Ababa Ethiopia.",
      "Alternative Technologies and Renewable Resources for Sustainable Development, Austria",
      "Human Resources Development in Companies at InWent, Mannheim, Germany",
      "CISCO iExecutive Development Program Seminar (Business Essentials Training of the Trainers), in Cairo, Egypt",
       "‘NetTel@Africa’ ICT Capacity Building Program’, Delft University of Technology, Netherlands.",
      
    ],
    experience:[
        {
          title: 'Ultimate Consultancy Services (UCS)',
          duration:'Founder and Managing Director (2012- present)',
        },
        {
          title: 'Oromia Bank',
          duration:'Board Director and Chairperson of the Branch Transformation, IT, and Corporate Marking and Rebranding Committee   (2020 to present)',
        },
        {
          title: 'AwashBank',
          duration:'Member of Board Nomination and Election Committee (from Nov. 2018 to Nov. 2019)',
        },
        {
          title: 'Awash Bank',
          duration:'Board Director and a member of the Strategic Committee for Awash Bank’s “Vision 2025 Project”  (2012-2017)',
        },
        {
          title: 'Gemshu Beyene Construction PLC',
          duration:'Adviser to the Company and coordinator for organizational transformation & Dev’t initiatives (2012-2018)',
        },
        {
          title: 'College of Telecommunications and Information Technology',
          duration:'Various positions including Department Headship and Assistant Deanship (2003-2013)',
        },
        {
          title: 'Commercial Bank of Ethiopia',
          duration:'Various positions including Senior Training Officer (1994-1998)',
        },
        {
          title: 'Jimma University',
          duration:'Head of Department of Management and Lecturer in Management',
        },
        {
          title: 'MoFED',
          duration:' Associate Consultant to conduct an assessment and produce public finance management institutionalized training strategic action plan for federal and regional financial management',
        },
        {
          title: 'Modern East African School',
          duration:' Associate Consultant to conduct an assessment and produce public finance management institutionalized training strategic action plan for federal and regional financial management',
        },
        {
          title: 'MoFED',
          duration:'Board Chairman (from January 2016 to December 2021)',
        },
        {
          title: 'Ethiopian Project Management Professionals Associations (EPMPA)',
          duration:'Founder and President (since 2017)',
        },
        {
          title: 'College of Telecommunications and Information Technology',
          duration:'Secretary, Board of Trustees (2005 to 2008)',
        },
        {
          title: 'Oromia International Bank S.C.',
          duration:'member of the Project Office and Human Resource Manager  during its establishment (October 2007 to March 2008)',
        },

    ],
    worked_with:[
      "Awash International Bank S.C. (AIB)",
      "Oromia International Bank S.C (OIB)",
      "Global Bank of Ethiopia (GBE)",
      "Awash Insurance Company S.C. (AIC)",
      "United Insurance S.C. (UNIC)",
      "Ethiopian Water Technology Institute (EWTI)",
      "Ministry of Agriculture (MOA)",
      "Minaye Business Group PLC ",
      "DH GEDA Trade and Industry PLC ",
      "Kenera International Trading PLC",
      "Officials of the Secretariat’s Office of the Ethiopian House of People’s Representatives", 
      "Ethiopian Water Technology Institute (EWTI) ",
      "Gemshu Beyene Construction PLC",
      "Homa Construction PLC",
      "Elilly International Hotel, and",
      "Transilvania Business and Manufacturing PLC.",
      
    ],

  },
  {
    cover: "../images/team/Tamrat-Bayle.jpg",
    name: "TAMRAT BAYLE (Dr. Eng.)",
    phone:'+251‐91‐1256503',
    email:'tamratbm@yahoo.com  tamrat@jepicstech.com',
    position:" Associate Consultant and Lead IT Expert, Ultimate Consultancy Services (UCS)",
    education_degree:'ELECTRICAL ENGINEERING, TECHNOLOGY FACULTY, ADDIS ABABA UNIVERSITY',
    education_masters:'ELECTRONIC ENGINEERING, EINDHOVEN UNIVERSITY OF TECHNOLOGY, THE NETHERLANDS Thesis: “Identification and Authentication of Medical Image Data Communication (Digital/Electronic Signature)” at Philips Medical Systems in The Netherlands. ',
    education_phd:'GRADUATE SCHOOL OF ENGINEERING, HIROSHIMA UNIVERSITY, JAPAN Doctor of Engineering in Information Engineering, specialized in IP Networking and Internet. Dissertation: “Providing Quality of Service (QoS) in the Internet with MPLS and DiffServ Mechanisms”.',
    professional_background:'     Involved in planning, coordinating, managing, directing and supervising of several ICT related projects/programs, including: Project Management of Bank of Abyssinia (BoA) new Hyper‐Converged Data Center design and implementation project, and Project Management of Commercial Bank of Ethiopia (CBE) Data Center LAN Upgrade Project. Also, critically involved in coordinating, initiating and directing several projects at the College of Telecommunications & Information Technology (CTIT) of Ethiopian Telecommunications Corporation (ETC)/Ethio Telecom with numerous partner organizations both at the national and international level, MIS consulting and supervision services for the Federal Rented Houses Agency (RHA), involved in a number of Ethiopian Information and Communication Technology Development Agency (EICTDA) ICT Projects, participated in Japan Gigabit Network and IPv6 projects, involved in the onsultation, design & implementation of the Addis Ababa University (AAU) AAUNet and AAU’s Finance Systems projects, acted as a local counterpart for the World Bank Energy I Project in Ethiopia, among others.',
    certificates:'Participated in a number of advanced International trainings and workshops on Computer Networks design, implementation, network management and administration, and Communications, Telecommunications, Internet Technologies, Backbone Internetworking Technologies, Routing Protocols, Software Programming, Web Designing, Internet/Web technologies, tools and applications, Systems Software Development (including network software development), as well as Management of Higher Education Institutions, Broadcasting Policy in Convergence Era, Leadership, ICT Leadership and Management, Leadership in the Telecom Industry, and many more. ',
    grants:[
      'March 2015: Recipient of the ISOC Fellowship to the IETF 92, Dallas, Texas.', 
      'June 2008: Recipient of “The Internet Society (ISOC) Fellowship to the Internet Engineering Task Force (IETF)”, IETF 72, Dublin, Ireland.' ,
      'May 2006: Recipient of the “2006 HP Technology for Teaching in Higher Education Grant Award”, valued at more than $70,000. Project Title: “Increasing Interaction in the Classroom with Mobile Wireless Technology”.' ,
      "April 1999 ‐ March 2004: Recipient of Japanese Government (Monbusho) Scholarship." ,
      "Jan 1992 ‐ May 1993: Recipient of The Netherlands Fellowship Programmes’s Scholarship"
    ],
    journal_papers:[
      "1) T. Bayle, R. Aibara and K. Nishimura, “Supporting Real‐Time IP Traffic with Enhanced Service Classes in DiffServ Networks,” IEE Proceedings – Communications, Special issue on Internet Protocols, Technology and Applications (VoIP), Vol. 151, No. 3, pp. 222–230, June 2004. ",
      "2) Tamrat Bayle, Reiji Aibara and Kouji Nishimura, “MEPFQ: Efficient and Fair Scheduling Mechanism for Real‐Time Multimedia Applications in Differentiated Services Networks,” IEICE Trans. on Commun. (Special Section on Internet Technology IV), Vol.E87‐B, No.3, pp. 615–625, March 2004.", 
      "3) Tamrat Bayle, Reiji Aibara and Kouji Nishimura, “Using MPLS Traffic Engineering to Minimize the Effects of Congestion in IP Networks,” IEICE Trans. on Commun., Special Section on the APCC2001 Proceedings, Vol.E84‐B, No.11, pp. 240–243, Sept. 2001." 
    ],
    refered_papers:[
      '1) Tamrat Bayle, “Innovative Use of Mobile Wireless Technology to Improve Teaching and Learning Environment in Higher Education,” ICELW 2009: The International Conference on E‐Learning in the  Workplace, Columbia University, New York, June, 2009. ‐> Accepted for Journal Publication. ',  
      '2) Tamrat Bayle, “Increasing Interaction in the Classroom with Wireless Technology,” IFIP World Information Technology Forum (WITFOR) 2007: ICT FOR DEVELOPMENT AND PROSPERITY, Addis Ababa, Ethiopia, August, 2007.', 
      '3) Tamrat Bayle, “Increasing Interaction in the Classroom with Mobile Wireless Technology,” 2007 HP Technology for Teaching Worldwide Higher Education Conference, Monterey, California, USA, February, 2007. ',
      '4) Tamrat Bayle, Reiji Aibara and Kouji Nishimura, “On Fairness of Scheduling Algorithms for Delay‐ Critical Multimedia Traffic over the Internet,” Proc. of the 15th IASTED International Conference on Modelling and Simulation (MS 2004), pp. 430–435, Marina Del Rey, California, USA, March 2004. ',  
      '5) Tamrat Bayle, Reiji Aibara and Kouji Nishimura, “Delivering Improved Multiple Services to IP Traffic in DiffServ Networks,” Proc. of The 28th Annual IEEE Conference on Local Computer Networks (LCN 2003), pp. 297–298, Bonn/Knigswinter, Germany, Oct. 2003. ',  
      '6) T. Bayle, R. Aibara and K. Nishimura, “Providing Enhanced Differentiated Servicesfor Real‐Time Traffic in the Internet,” Proc. of 6th IFIP/IEEE International Conference on Management of Multimedia Networks and Services (MMNS 2003), LNCS 2839, Springer‐Verlag, pp. 272–285, Belfast, Northern Ireland, UK, Sept. 2003.',   
     ' 7) Tamrat Bayle, Reiji Aibara and Kouji Nishimura, “Scheduling IP Traffic for Enhanced Servicesin DiffServ Networks,” Proc. of the 8th Asia‐Pacific Conference on Communications (APCC2002), pp. 347–350, Bandung, Indonesia, Sept. 2002. ‐> selected as the Best Paper of APCC2002. ',  
      '8) Tamrat Bayle, Reiji Aibara and Kouji Nishimura, “Using MPLS Traffic Engineering to Minimize the Effects of Congestion in IP Networks,” Proc. of the 7th Asia‐Pacific Conference on Communications (APCC2001), pp. 240–243, Tokyo, Japan, Sept. 2001. ',
      '9) Tamrat Bayle, Reiji Aibara and Kouji Nishimura, “Performance Measurements of MPLS Traffic Engineering and QoS,” Proc. of ISOC International Networking Conference (INET2001), 13 pages (CD‐ ROM) Stockholm, Sweden, June 2001. '
    ],
    non_refered_publication:[
      '1) Tamrat Bayle, “Increasing Interaction in the Classroom with Wireless Technology,” Research Project Progress Poster Presentation at HP Technology for Teaching Regional Higher Education Forum for Europe, the Middle East, and Africa (EMEA), Imperial College, London, UK, October, 2007.' ,  
      '2) Tamrat Bayle, “Providing Quality of Service in the Internet with MPLS and DiffServ Mechanisms,” PhD Dissertation, Hiroshima University, Hiroshima, Japan, March 2004.',   
      '3) Tamrat Bayle, Reiji Aibara and Kouji Nishimura, “Scheduling Mechanism for Real‐Time IP Traffic in DiffServ Networks,” Proc. of Forum on Information Technology 2002 (FIT2002), pp. 3–4, Tokyo, Japan, Sept. 2002.' ,
     ' 4) Hirohisa Fujiwara, Tamrat Bayle, Kouji Nishimura and Reiji Aibara, “Performance Evaluation of QoS in IP  Networks Using Simulation,” Technical Report of IEICE, NS2002‐174, CQ2002‐109, TM2002‐44, pp. 5–10, Nov. 2002, (in Japanese).',   
      '5) Hirohisa Fujiwara, Tamrat Bayle, Kouji Nishimura and Reiji Aibara, “Performance Evaluation of QoS Networks,” Technical Report of IEICE, IA2002‐5, pp. 31–37, June 2002, (in Japanese). ',
     ' 6) Tamrat Bayle, “A Survey of QoS Mechanisms in IP Networks,” Technical Report, Hiroshima University, Hiroshima, Japan, May 2000. ',
      '7) Tamrat Bayle, “Identification and Authentication of Medical Image Data Communication (Digital Signature),” Master Thesis, Eindhoven University of Technology, The Netherlands, May 1993.' ,
      '8) Tamrat Bayle, “Data Communication of Controller Area Network (CAN): Interactive Test Software for the i82526 CAN Chip,” Industrial Summer Project, Philips Medical Systems, The Netherlands, Aug. 1992.' ,
      '9) Menbere Gebremariam, and Tamrat Bayle, “Mark Transfer from Original Paper onto Electro‐Stencil by Electronic Means,” B.Sc. Final Project, Addis Ababa University, Ethiopia, July 1988.' , 

    ]
  },
  {
    cover: "../images/team/kiram.png",
    name: "Kiram Tadesse",
    position:"Buisness Development Manager, Ultimate Consultancy Services (UCS)",
    professional_background:'KIRAM TADESSE is a consultant, researcher and trainer. Having a Business Development Managerial role with UCS, he executes tasks ranging from managing projects, and delivering trainings to driving business growth of the firm. He also researches new market opportunities and oversees growth projects as well as develops a network of contacts to attract new clients while retaining the existing ones. He consulted multiple private, public/governmental, regional and international organizations, INGOs, and diplomatic corps. He specializes on development, business, and communication. Kiram has extensive experience on the aspects of geopolitics in tandem with trans-national resource management along with diplomacy, policy and governance practices, and published several articles. He serves as analyst with local and international media on national and regional affairs. He has championed continental and global initiatives. Kiram holds degrees in Regional and Local Development Studies and in Management. He has partaken in various high level academic seminars in Africa, Asia and Europe.  ',
  },
  
  {
    cover:"../images/team/jaleta.jpg",
    name: 'Jaleta Tesgera',
    position:"Software Developer, Ultimate Consultancy Services (UCS)",
    phone:'218-979-8081',
    email:'jtesgera@cord.edu',
    linkedIn :'linkedin.com/in/jaft24',
    github :'github.com/jaft24',
    website:'jafetaftesgera.com',
    education:{
     school:'Concordia College',
     description:'B.A. in Computer Science, Concentration in Computing, Minors: Math and Data Analytics',
     gpa:'3.87',
    },
    student_organizations:'• ColorStack, VP of Software Engineering Club, MLK Day Events Coordinator, Mathematics Club, Black Student Union',
    relevent_course_work:'• Fundamental Structures, Operating Systems, Mobile Application Development, Web design and Programming, Statistical Models with R, Database Management Systems, Data Analytics, BattleBots, and Arduino',
    technical_skills:{
     programming_languages:'• Java, C++, Kotlin, HTML, CSS, Java Script, Python, PHP, SQL, Arduino, R, and RegEx',
     developer_tools:'• VS Code, Net Beans, Visual Studio, Git, Android Studio, Code Blocks, R Studio, Tableau, GitHub, Autodesk, cPanel, SQL Workbench, and XAMPP'
    },
    work_experience:[
     {
       place:'ITS Concordia College',
       position:'Student Manager in Training',
       description:[
         '• Managed and run diagnostics biweekly on more than a hundred technical equipment on campus which include desktops, laptops, printers, routers, projectors, and TVs to prevent technical inconveniences',
         '• Installed and repaired common hardware, software, and Wi-Fi connectivity problems on campus through troubleshooting for 10 to 15 students and faculty per shift who are facing problems in these areas'
        ]
     },
     {
       place:'Precision Clinical Laboratories',
       position:'Lab Accessioning Technician (Voluntary)',
       description:[
        '• Accessioned over a 100 blood, urine samples, and COVID tests per day into the laboratory database for tracking patient medical history and manage their prescriptions',
        '• Troubleshoot and updated laboratory computers, label-printers before and after shifts for consistent functionality',
        '• Effectively communicated with 20 or more clients per day for their lab registration, sampling, and results',
        ]
     },
     {
       place:'Dining Services Concordia College',
       position:'Student Manager',
       description:[
         '• Managed a team of five to eight students in every shift five times a week across tasks for a coordinated work flow',
         ' • Trained new student staffs for five to ten hours per week on how to complete their tasks, communicate with student clients and cooks to result in motivated work-oriented student staff',
         ' • Controlled the itinerary and communicated those reports to my supervisors to provide consistent service and aliment for students and faculty'
        ]
     },
    ],
    projects:[
     {
       title:'NewRoz Kebab Restaurant Website | HTML, CSS, Java Script, PHP, SQL, Git',
       description:[
        '• Full-stack web development from scratch using multiple front-end languages like HTML, CSS, and Java Script utilizing VS code, and team collaboration over Git to build an aesthetic 15-page local restaurant website',
        '• Developed admin control, login and carting systems, stored cookies, validated, stored, and emailed user submitted responses using back-end languages like PHP, and SQL and organized them using SQL Workbench',
        '• Communicated with the manager of the local restaurant to understand expectations and receive feedback'
       ]
     },
     {
       title:'Breaking the Poverty Cycle of Agriculture | Arduino, SolidWorks CAD, KUMU, Canva',
       description:[
         '• Collaborated with NDSU in a competition by Oxford University, examined the agricultural poverty cycle in Ethiopia, and introduced utilization of AI by proposing an Arduino based land information analyzing prototype',
         '• Employed vast use of embedded software development to address food insecurity and promote a healthier life'
       ]
     },
     {
       title:'Physics Quiz and Simulator Software | Java, Adobe Photo-shop, NetBeans',
       description:[
         '• Designed and programmed the front end and back end of a 2D creative quiz with Java and an interactive GUI on NetBeans by utilizing Adobe Photo-shop for middle school physics students',
         '• Utilized Object Oriented Programming model concepts and performed rigorous unit and system testing'
       ]
     },
     {
       title:'Diabetic Patients’ Analysis | Tableau, Excel, RegEx',
       description:[
         '• Researched and performed regression analysis and visual data representation of patient data with charts and ROC curves using Tableau to find diabetic patient re-admittance predictors and their chances from a hospital database',
      
       ]
     },
    ]
 
 
   },
  {
    cover: "../images/team/habtamu.jpg",
    name: "Habtamu Sileshi",
    position:"Accountant, Ultimate Consultancy Services (UCS)",
  },
  {
    cover: "../images/team/talile.jpg",
    name: "Talile Kebede",
    position:"Marketing and Social Media Specialist, Ultimate Consultancy Services (UCS)",
  },
  
]
export const price = [
  {
    plan: "Basic",
    price: "29",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "49",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "79",
    ptext: "2 user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "Contact",
    text: [{ list: "Email: \nfanta.tesgera@ucs.com.et     ftesgerajetu@yahoo.com" }, { list: "Telephone: \n+251911256485 \n+251911445721" }, { list: "P.o.Box 12682, Addis Ababa, Ethiopia " }, { list: "Address: \nYeka Sub-City, Woreda 12, House No. 1027" }],
  },
]
