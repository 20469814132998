import React from "react"
import { Link } from "react-router-dom"
import Heading from "../../common/Heading"
import HeadingH1 from "../../common/HeadingH1"

const HomeCard = ({ item: { id, cover, name, rating, time, desc, starring, genres, tags, video } }) => {
  return (
    <>
      <div className='box' style={{height:'100%'}}>
        <div className='coverImage'>
          <img src={cover} alt='' />
        </div>
         <div>
          <HeadingH1 color='white' title='Ultimate Consultancy Services (UCS)' subtitle='Think Agile, Get Inspired for Change!' style={{color:'#efb42c'}} />
  
         </div>
      </div>
    </>
  )
}

export default HomeCard
