import React,{useState} from "react"
import Featured from "./featured/Featured"
import Hero from "./hero/Hero"
import Team from './team/Team'
import Recent from "./recent/Recent";
import Training from "../training/Training";
import { homeData } from "../data/Data";
import Partners from "./partners/Partners"

const Home = () => {
  const [items, setItems] = useState(homeData)
  return (
    <>
      <Hero items={items}/>
      <Featured />
      <Recent />
      <Training/>
      <Partners/>
    </>
  )
}

export default Home
